import {Button} from "@/components/button";
import React from "react";

interface TicketSelectedProps {
    selectedTicket: any;
    ticketQuantity: number;
    setTicketQuantity: (quantity: number) => void;
}

export const TicketSelected: React.FC<TicketSelectedProps> = ({selectedTicket, ticketQuantity, setTicketQuantity}) => {
    return (
        <>
            {selectedTicket.id && <div>
                {selectedTicket.number_tickets_remaining > selectedTicket.max_buy &&
                    <p className='block text-xs text-danger'>Maksimal pembelian
                        tiket: {selectedTicket.max_buy}/user</p>}
                {selectedTicket.number_tickets_remaining < selectedTicket.max_buy &&
                    <p className='block text-xs text-danger'>Tiket hanya tersisa lagi
                        {' '} {selectedTicket.number_tickets_remaining}</p>}
            </div>}

            {selectedTicket.id && <>
                <p className='mr-auto text-sm font-medium'>Atur jumlah tiket</p>
                <div className='flex items-center gap-3'>
                    <div
                        className='flex items-start gap-1 border-[1px] border-neutral-40 self-start rounded-md p-2 text-sm'>
                        <Button onClick={() => {
                            if (ticketQuantity !== 0) {
                                setTicketQuantity(ticketQuantity - 1);
                            }
                        }}>-</Button>
                        <span className='w-[31px] text-center my-auto'>{ticketQuantity}</span>
                        <Button onClick={() => {
                            if (selectedTicket.number_tickets_remaining < selectedTicket.max_buy) {
                                if (ticketQuantity < selectedTicket.number_tickets_remaining
                                    && selectedTicket.number_tickets_remaining < selectedTicket.max_buy) {
                                    setTicketQuantity(ticketQuantity + 1);
                                }
                            } else if (ticketQuantity < selectedTicket.max_buy) {
                                setTicketQuantity(ticketQuantity + 1);
                            }
                        }}>+</Button>
                    </div>
                </div>
            </>}
        </>
    )
}
