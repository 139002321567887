import React from "react";
import {CheckIcon} from "@heroicons/react/24/outline";
import {ExclamationCircleIcon, TicketIcon} from "@heroicons/react/24/solid";
import {FormatCurrency} from "@/routes/root";
import {Tag} from "@/components/tag";
import {HSeparator} from "@/components/separator";
import {Button} from "@/components/button";
import moment from "moment";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {SelectTicket} from "@/reducers/tickets";
import {cn} from "@/lib/utils";
import "moment/locale/id";

export interface TicketCardProps {
    ticket: any,
    mode?: 'private' | 'public';
    bgColor: string;
    textColor: string;
    isCurrentTicket?: boolean;
    onTicketClicked?: (id: string) => void;
}

moment.locale("id");

export const TicketCard: React.FC<TicketCardProps> = ({
                                                          ticket,
                                                          mode = 'public',
                                                          bgColor,
                                                          textColor,
                                                      }) => {
    const dispatch: AppDispatch = useDispatch();
    const {selectedTicket} = useSelector((state: any) => state.tickets);
    const isPrivate = mode === 'private';

    return (
        <div className="w-full rounded overflow-hidden border border-gray-300">
            <div className={cn(`text-white px-4 py-2 h-[80px] grid grid-cols-5 gap-2 items-center`, bgColor)}>
                <div className="flex items-center col-span-3">
                    <div className={cn(`bg-white rounded-full p-2 mr-2`, textColor)}>
                        <TicketIcon className={cn(`h-[20px] w-[20px]`, textColor)}/>
                    </div>
                    <span className="font-semibold">{ticket.name}</span>
                </div>
                <div className={'col-span-2'}>
                    <div className={'flex justify-end'}>
                      <span className="text-white font-bold">
                        <FormatCurrency amount={ticket.price}/>
                      </span>
                    </div>
                    <div className={'flex justify-end'}>
                        <Tag color={ticket.ticket_label_status?.color}>{ticket.ticket_label_status?.label}</Tag>
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 bg-white">
                <p className={'list-disc pl-4 text-gray-600 whitespace-pre-wrap'}>{ticket.description ?? "Tidak ada keterangan"}</p>
            </div>
            <div>
                <HSeparator/>
                <div className="px-4 py-3 bg-white my-auto">
                    {(ticket.is_waiting_open && TicketWillOpen(ticket))}
                    {ticket.is_open && TicketAlreadyOpen(isPrivate, dispatch, ticket, selectedTicket)}
                    {(ticket.is_sold || ticket.is_sell_date_end) && TicketAlreadyClosed()}
                </div>
            </div>
        </div>
    );
};

const TicketWillOpen = (ticket: any) => {
    return (
        <div className={'flex justify-between'}>
            <div className={'flex gap-1'}>
                <ExclamationCircleIcon className='h-[20px] w-[20px] my-auto text-red-500'/>
                <span className="text-sm font-semibold">
                    Tiket akan segera dijual
                </span>
            </div>
            <div>
                <span
                    className="text-sm font-light text-red-600">{moment(ticket.start_sell_at).fromNow()}
                </span>
            </div>
        </div>
    );
}

const TicketAlreadyClosed = () => {
    return (
        <div className={'flex justify-between'}>
            <div className={'flex gap-1'}>
                <ExclamationCircleIcon className='h-[20px] w-[20px] my-auto text-red-500'/>
                <span className="text-sm font-semibold">
                    Tiket sudah selesai/habis terjual
                </span>
            </div>
        </div>
    );
}

const TicketAlreadyOpen = (isPrivate: boolean, dispatch: AppDispatch, ticket: any, selectedTicket?: any) => {
    return (
        <div className={'flex justify-between'}>
            <div className={'flex gap-1 my-auto'}>
                <CheckIcon className='h-[20px] w-[20px] my-auto text-green-500'/>
                <span className="text-sm font-semibold">
                        Tiket masih bisa dibeli
                    </span>
            </div>
            {
                !isPrivate &&
                <div>
                    <Button look={'outline'} variant={'neutral'} onClick={() => {
                        dispatch(SelectTicket(ticket));
                    }}>{selectedTicket.id === ticket.id ? 'Dipilih' : 'Pilih'}</Button>
                </div>
            }
        </div>
    );
}
