import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {HSeparator} from "@/components/separator";
import {Modal} from "@/components/modal";
import React from "react";
import voucherBadge from "@/routes/guest/event/_voucher/badge.png";
import {idrFormatter} from "@/lib/utils/currency";


interface VouchersProps {
    total?: number;
    showVoucher: boolean;
    setShowVoucher: (show: boolean) => void;
    vouchers: Voucher[];
    setSelectedVoucher: (voucher: Voucher) => void;
}

interface Voucher {
    id: number;
    code: string;
    name: string;
    amount: number;
    type: string;
}

export const VoucherLabel: React.FC<{ label: string, code: string, amount: number, type: string }> = ({
                                                                                                          label,
                                                                                                          code,
                                                                                                          amount,
                                                                                                          type
                                                                                                      }) => {
    return (
        <div className={'flex gap-2'}>
            <img className={'w-16'} src={voucherBadge} alt={label}/>
            <div className={'flex flex-col'}>
                <span className='text-sm font-semibold'>{code}</span>
                <span className='text-xs font-light'>{label}</span>
                {type === "fixed" &&
                    <span className='text-xs font-light'>Potongan: {idrFormatter.format(amount)}</span>}
                {type === "percentage" && <span className='text-xs font-light'>Potongan: {amount}%</span>}
            </div>
        </div>
    );
};

export const VoucherModal: React.FC<VouchersProps> = ({
                                                          total = 0,
                                                          showVoucher,
                                                          setShowVoucher,
                                                          vouchers,
                                                          setSelectedVoucher,
                                                          ...props
                                                      }) => {
    return (
        <>
            <Modal
                {...props}
                show={showVoucher}
                onRequestClose={() => {
                    setShowVoucher(false)
                }}
                title='Voucher Tersedia'
                body={
                    <div className={'flex flex-col gap-2'}>
                        {vouchers?.map((voucher: any, index: number) => (
                            <>
                                <div
                                    className={`relative flex justify-between gap-2 p-2 ${(!voucher.status || total < 10000) ? '' : 'cursor-pointer'}`}
                                    key={index}
                                    onClick={() => {
                                        if (voucher.status && total >= 10000) {
                                            setSelectedVoucher({
                                                id: voucher.id,
                                                code: voucher.code,
                                                name: voucher.name,
                                                amount: voucher.amount,
                                                type: voucher.type
                                            })
                                            setShowVoucher(false)
                                        }
                                    }}>
                                    {(!voucher.status || total < 10000) ?
                                        <div className={'absolute right-4 my-3 text-red-600 text-sm'}>Tidak
                                            Tersedia</div> : null}
                                    <VoucherLabel label={voucher.name} code={voucher.code} amount={voucher.amount}
                                                  type={voucher.type}/>
                                    {(voucher.status && total >= 10000) ?
                                        <ChevronRightIcon className='h-[20px] w-[20px] text-black my-auto'/> : null}
                                </div>
                                <HSeparator/>
                            </>
                        ))
                        }
                    </div>
                }
            />
        </>
    )
}
