import {HSeparator} from '@/components/separator';
import React, {useEffect, useRef, useState} from 'react';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Modal} from "@/components/modal";
import {GetIssuedTickets} from "@/services/tickets";
import {ArrowLeftIcon, CreditCardIcon, QrCodeIcon} from "@heroicons/react/24/solid";
import {Button} from "@/components/button";
import html2canvas from "html2canvas";
import {Tag} from "@/components/tag";
import moment from "moment";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
    label?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({label, children}) => {
    return (
        <div className='flex flex-col gap-4 mx-4 md:mx-24'>
            <h1 className='text-2xl font-semibold'>{label}</h1>
            {children}
        </div>
    );
};

const defaultTicketValue = {
    isModelOpen: false,
    ticket: {
        number: '',
        qr_code: '',
        name: ',',
        type: ',',
        description: ',',
        serial: ','
    }
};

export function Ticket() {
    const {slug} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const {issuedTicket} = useSelector((state: any) => state.tickets);
    const [showTicket, setShowTicket] = useState(defaultTicketValue);

    const screenRef = useRef<HTMLDivElement>(null);

    const handleDownloadScreenshot = async (ticketNumber: string) => {
        if (screenRef.current) {
            try {
                const canvas = await html2canvas(screenRef.current);

                const ctx = canvas.getContext('2d');
                if (ctx) {
                    // Set watermark text properties
                    const watermarkText = 'TKVT';
                    ctx.font = '30px Arial'; // Font size and style
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black
                    ctx.textAlign = 'center'; // Center the text
                    ctx.textBaseline = 'middle'; // Align text vertically

                    // Position watermark in the center of the canvas
                    ctx.fillText(watermarkText, canvas.width / 2, canvas.height / 2);
                }

                const imgData = canvas.toDataURL('image/png');

                const link = document.createElement('a');
                link.href = imgData;
                link.download = ticketNumber + '.png';

                link.click();
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };

    useEffect(() => {
        dispatch(GetIssuedTickets(slug))
    }, [dispatch, slug]);

    return (
        <>
            <Modal
                show={showTicket.isModelOpen}
                onRequestClose={() => {
                    setShowTicket(defaultTicketValue)
                }}
                title='Detail Tiket'
                body={
                    <>
                        <div className={'flex flex-col gap-2 p-2 relative'} ref={screenRef}>
                            <div className={'text-center mb-8'}>
                                <h1 className={'underline text-4xl'}>TKVT-{showTicket.ticket.number}</h1>
                            </div>
                            <div className={'flex justify-between'}>
                                <p className='text-base font-semibold'>Nama Event</p>
                                <p className='text-base font-light w-1/2 text-right'>{issuedTicket.event?.name}</p>
                            </div>
                            <div className={'flex justify-between'}>
                                <p className='text-base font-semibold'>Nama Tiket</p>
                                <p className='text-base font-light w-1/2 text-right underline'>{showTicket.ticket.name}</p>
                            </div>
                            <div className={'flex justify-between'}>
                                <p className='text-base font-semibold'>{showTicket.ticket.type === "Showtime" ? "Jam tayang" : "Deskripsi"}</p>
                                <p className='text-base font-light w-1/2 text-right'>{showTicket.ticket.description}</p>
                            </div>
                            <div className={'flex justify-center'}>
                                <img className={'w-full'} src={showTicket.ticket.qr_code}
                                     alt={showTicket.ticket.number}/>
                            </div>
                        </div>
                    </>
                }
                footer={<>
                    <div>
                        <Button className={"w-full"} onClick={() => {
                            handleDownloadScreenshot(showTicket.ticket.number)
                        }
                        }>Download Tiket</Button>
                    </div>
                </>}
            />

            <Section>
                <div>
                    <div className="text-900 text-2xl font-semibold">Halo, ini dia tiketmu 🎫</div>
                    <p className={"text-400 mb-4"}>Berikan QR kepada panitia untuk scan tiket</p>
                </div>

                <div>
                    <div className={'flex justify-between'}>
                        <p className='text-base font-medium'>Kode Booking</p>
                        <p className='text-base font-medium'>{issuedTicket.booking_reference}</p>
                    </div>

                    <div className={'flex justify-between'}>
                        <p className='text-base font-medium'>Nama Event</p>
                        <p className='text-base font-medium'>{issuedTicket.event?.name}</p>
                    </div>
                </div>

                <div className={"flex flex-col gap-4 justify-center"}>
                    {issuedTicket?.tickets?.map((ticket: any, index: string) => (
                        <div key={index} className={'cursor-pointer'} onClick={() => {
                            setShowTicket({
                                isModelOpen: true,
                                ticket: ticket
                            })
                        }}>
                            <div className="cursor-pointer flex flex-row justify-between items-center mb-4">
                                <div className="flex flex-col">
                                    <span className="font-bold">{ticket.name}</span>
                                    <div className="flex align-items-center">
                                        <QrCodeIcon className={'h-[24px] w-[24px]'}/>
                                        <span>{ticket.number}</span>
                                    </div>
                                </div>
                                <QrCodeIcon className={'h-16 w-16'}/>
                            </div>
                            <HSeparator/>
                        </div>
                    ))}
                </div>
                {['Belum Bayar', 'Kadaluarsa'].includes(issuedTicket?.payment?.status?.label) && <div>
                    <div className={'flex justify-between gap-2'}>
                        <div>
                            <img className={'w-16'}
                                 src={`/assets/channels/${issuedTicket?.payment?.channel?.code?.toLowerCase()}.png`}
                                 alt={issuedTicket?.payment?.channel?.name}/>
                        </div>
                        <div className={'my-auto'}>
                            <Tag
                                color={issuedTicket?.payment?.status?.color}>{issuedTicket?.payment?.status?.label}</Tag>
                        </div>
                    </div>
                    {issuedTicket?.payment?.status?.label === 'Belum Bayar' && <div className={'mt-4'}>
                        <p className={'text-md font-semibold text-center'}>Ups, ternyata belum dibayar <br/>Silakan
                            bayar sebelum</p>
                        <p className={'text-lg font-bold text-center animate-pulse'}>{moment(issuedTicket?.payment?.expired_at).format('lll')}</p>
                    </div>}
                </div>}
                {issuedTicket?.payment?.status?.label === 'Belum Bayar' ? <Button className={'mt-2'} onClick={() => {
                    window.location.href = issuedTicket.payment?.channel?.checkout_url;
                }} leftIcon={<CreditCardIcon/>}>Bayar</Button> : <Button className={'mt-2'} onClick={() => {
                    window.location.href = '/' + issuedTicket.event?.slug;
                }} leftIcon={<ArrowLeftIcon/>}>Beli Tiket Lagi</Button>}
                <div className={'text-center'}>
                    <small>Jika kamu butuh bantuan silakan menghubungi tim kami <a
                        className={'underline text-blue-500 font-semibold'} target={'_blank'}
                        href="https://wa.me/6281238169667">disini</a></small>
                </div>
            </Section>
        </>
    );
}
